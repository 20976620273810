@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: #181a21;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
      45deg,
      #1b1d24 25%,
      transparent 25%,
      transparent 75%,
      #1b1d24 75%
    ),
    linear-gradient(
      45deg,
      #1b1d24 25%,
      transparent 25%,
      transparent 75%,
      #1b1d24 75%
    );
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #81f09e;
}

::selection {
  color: #fff;
  background: #81f09e;
}

a:hover {
  cursor: url("img/cursor.png"), pointer;
}

.last-line {
}

.last-line-row {
  position: relative;
}

.last-line-element {
  position: relative;
  z-index: 2;
}

.last-line-row i {
  position: absolute;
  bottom: 3px;
  left: -8px;
  right: -8px;
  height: 38%;
  background: #49e571;
  z-index: 1;
  background-image: url("img/highlight.png");
  transform: rotate(-2deg);
  border-radius: 1px;
}

.border-main {
  border-color: #2f3138;
}

@media (max-width: 768px) {
  .last-line-row i {
    bottom: 0px;
    height: 45%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.txtlink {
  display: inline-block;
  padding: 10px 0 0;
}

.txtlink:hover span {
  background: #49e571;
}

@media (max-width: 768px) {
  .txtlink {
    padding: 0;
  }
}

.font-mono {
  font-family: "Encode Sans Condensed", sans-serif;
}

.bg-dark {
  background: #282a31;
}

.bg-dark2 {
  background: #3f4148;
}

.bg-dark3 {
  background: #5a5c63;
}

.border-main2 {
  border-color: #4c4e55;
}

.border-main3 {
  border-color: #707279;
}
